import * as React from "react";
import {Button} from 'reactstrap';
import { setData } from "../../store/reducers/UserSlice";
import { useAppDispatch } from "../../hooks/redux";
import { addRow, deleteRow,  resetData, resetValues } from "../../store/reducers/UserActionCreator";
import "./Grid.css";
import { useAppSelector } from "../../hooks/redux";

export function AddDelete(props) {
    const dispatch = useAppDispatch();
    var { id, field, value, colDef, rows, setRows } = props;
            // Delete Row Function
    const { data} = useAppSelector((state) => state.userReducer);


            // Insert Row function
    const onDelete = async(e) => {
        e.stopPropagation(); // don't select this row after clicking
        const selectedId = props.getValue(props.id, "id");
        const deleteContent = {
            "selectedId":selectedId,
            "data":rows
        }

        var resetResponse =await dispatch(
            resetData({
            oldData: rows,
            })
        );        
        
        const response  = await dispatch(deleteRow(deleteContent));
        let data = response.payload.data;
        setData(data)

        };
        // Insert Row function
        const onInsert = async(e) => {
            e.stopPropagation(); // don't select this row after clicking
            const selectedId = props.getValue(props.id, "id");
            const addContent = {
                "selectedId":selectedId,
                "data":rows
            }
        var resetResponse =await dispatch(
            resetData({
            oldData: rows,
            })
        );        

        const response  = await dispatch(addRow(addContent));
        let data = response.payload.data;
        setData(data)

        };

            return (
                <>
                    <Button
                        onClick={onInsert}
                        className="add-button"
                    >+</Button>

                    <Button
                        onClick={onDelete}
                        className="delete-button"
                    >−</Button>


                </>
            );
    };



export default AddDelete;
