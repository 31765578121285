import { ThemeProvider, Box, useTheme, Snackbar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import * as React from "react";
import { tokens } from "../Grid/Theme/theme";
import { sendFileEclipse } from "../../store/reducers/UserActionCreator";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Cookies from 'universal-cookie';

export async function copyTextToClipboard(text) {
    try {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    } catch (err) {
        console.log("Error while copying to clipboard")
    }
}

const SubmitComponent = (props) => {
    var { branchLoaded, idLoaded, fileSelected, setFileSelected, setSubmitAtempt, submitAtempt, eclipseSubmited, setBranchLoaded, setIdLoaded, setEclipseSubmited } = props;
    const dispatch = useAppDispatch();
    var { data, branch, eclipseLabel, loading } = useAppSelector((state) => state.userReducer);
    var [loading, setLoading] = React.useState(false)
    var [eclipseResponse, setEclipseResponse] = React.useState('Get Started');
    var [idResponse, setIdResponse] = React.useState('');
    var [buttonColor, setButtonColor] = React.useState('#007000')
    var [icon, setIcon] = React.useState('send');
    var [openSnack, setOpenSnack] = React.useState(false)

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    React.useEffect(() => {
        console.log("\n\n\t ---- SUBMIT BUTTON STATE -----: ");
        console.log("branchLoaded: ", branchLoaded);
        console.log("idLoaded: ", idLoaded);
        console.log("fileSelected: ", fileSelected);
        console.log("eclipseSubmited: ", eclipseSubmited);
        console.log("submitAtempt: ", submitAtempt);

        const cookies = new Cookies();
        const cookie = cookies.get('branch');
        setBranchLoaded(cookie !== undefined && cookie !== '0');
        setIdLoaded(cookie !== undefined && cookie !== '0');

        if (fileSelected && branchLoaded && idLoaded && eclipseSubmited && submitAtempt) {
            console.log('state 1')
            setButtonColor('#099990');
            setEclipseResponse("SUBMITED! ");
            setIcon('save');
        }

        if (fileSelected && branchLoaded && idLoaded && eclipseSubmited && !submitAtempt) {  // THIS STATE NEVER HAPPENSß
            console.log('state 2')
            setButtonColor('#db1432');
            setEclipseResponse("ERROR: Unreachable state ");
            setIcon('save');
        }

        if (fileSelected && branchLoaded && idLoaded && !eclipseSubmited && submitAtempt) {
            console.log('state 3')
            setButtonColor('#007000');
            setEclipseResponse("Submit to Eclipse! ");
            setIcon('send');
        }

        if (fileSelected && branchLoaded && idLoaded && !eclipseSubmited && !submitAtempt) {
            console.log('state 4')
            setButtonColor('#007000');
            setEclipseResponse("Submit to Eclipse! ");
            setIcon('send');
        }

        else if (fileSelected && !branchLoaded && !idLoaded && eclipseSubmited && submitAtempt) {  // THIS STATE NEVER HAPPENs
            console.log('state 5')
            setButtonColor('#dd9402');
            setEclipseResponse('Select a branch and input Eclipse ID')
            setIcon('warning');
        }

        else if (fileSelected && !branchLoaded && !idLoaded && eclipseSubmited && !submitAtempt) {  // THIS STATE NEVER HAPPENs
            console.log('state 6')
            setButtonColor('#db4432');
            setEclipseResponse("ERROR:  Unreachable state! ");
            setIcon('save');
        }

        else if (fileSelected && !branchLoaded && !idLoaded && !eclipseSubmited && submitAtempt) {
            console.log('state 7')
            setButtonColor('#dd9402');
            setEclipseResponse('Select a branch and input Eclipse ID')
            setIcon('warning');
        }

        else if (fileSelected && !branchLoaded && !idLoaded && !eclipseSubmited && !submitAtempt) {
            console.log('state 8')
            setButtonColor('#007000');
            setEclipseResponse("Submit to Eclipse! ");
            setIcon('send');
        }

        else if (!fileSelected && branchLoaded && idLoaded && eclipseSubmited && submitAtempt) {
            console.log('state 9')
            setButtonColor('#db4432');
            setEclipseResponse("ERROR:  Unreachable state! ");
            setIcon('save');
        }

        else if (!fileSelected && branchLoaded && idLoaded && eclipseSubmited && !submitAtempt) {
            console.log('state 10')
            setButtonColor('#db4432');
            setEclipseResponse("ERROR:  Unreachable state! ");
            setIcon('save');
        }

        else if (!fileSelected && branchLoaded && idLoaded && !eclipseSubmited && submitAtempt) {
            console.log('state 11')
            setButtonColor('#dd9402');
            setEclipseResponse('Please, select and load a file')
            setIcon('warning');
        }

        else if (!fileSelected && branchLoaded && idLoaded && !eclipseSubmited && !submitAtempt) {
            console.log('state 12')
            setButtonColor('#007000');
            setEclipseResponse("Submit to Eclipse! ");
            setIcon('send');
        }


        else if (!fileSelected && !branchLoaded && !idLoaded && eclipseSubmited && submitAtempt) {
            console.log('state 13')
            setButtonColor('#db4432');
            setEclipseResponse("ERROR:  Unreachable state! ");
            setIcon('save');
        }

        else if (!fileSelected && !branchLoaded && !idLoaded && eclipseSubmited && !submitAtempt) {
            console.log('state 14')
            setButtonColor('#db4432');
            setEclipseResponse("ERROR:  Unreachable state! ");
            setIcon('save');
        }

        else if (!fileSelected && !branchLoaded && !idLoaded && !eclipseSubmited && submitAtempt) {
            console.log('state 15')
            setButtonColor('#dd9402');
            setEclipseResponse('Please, select and load a file')
            setIcon('warning');
        }

        else if (!fileSelected && !branchLoaded && !idLoaded && !eclipseSubmited && !submitAtempt) {
            console.log('state 16')
            setButtonColor('#007000');
            setEclipseResponse("Submit to Eclipse! ");
            setIcon('send');
        }


    }, [branchLoaded, idLoaded, fileSelected, eclipseSubmited, submitAtempt])


    const handleProcessEclipse = async () => {
        //TODO: function to transform data into an eclipse readable
        setLoading(true);
        //console.log(`\n\n\n**** handleProcessEclipse branch: ${branch}`);
        const cookies = new Cookies();
        const cookie = cookies.get('branch');
        const username = cookies.get('username');
        setSubmitAtempt(true)

        if (cookie === undefined || cookie === '0') {
            setLoading(false);
        } else {
            //TODO: Insert redux function that scans the document and report formating errors.
            console.log("username var:", username)
            var response = await dispatch(sendFileEclipse({ fileData: data, branch: branch, username: username }))
            if (response.payload.code === 0) {
                setIdResponse(response.eclipseLabel)
                setLoading(false);
                window.scrollTo(0, 0);
                //navigator.clipboard.writeText(response.decodedOrder);
            } else if (response.payload.code >= 500) {
                setIdResponse(response.message)
                setLoading(false);
                window.scrollTo(0, 0);
                //navigator.clipboard.writeText(response.decodedOrder);
            }
            else {
                setLoading(false);
                //setOpenSnack(true);
                //navigator.clipboard.writeText(response.decodedOrder);
                setEclipseSubmited(true)
                console.log(`DATA SUBMITED TO ECLIPSE: : ${JSON.stringify(response.eclipseData)}`);
            }
        };
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                <div className="grid-container">
                    <Box
                        m="20px 0 0 0"
                        padding={"30px"}
                        paddingBottom={"80px"}
                        sx={{
                            width: 'auto',
                            // STYLE
                            "Button": {
                                textTransform: 'none',
                                border: "10px",
                                color: colors.grey[900],
                                background: buttonColor,
                                height: "50px",
                                width: "280px",
                                fontSize: "18px",
                                contrastText: "#fff", //button text white instead of black

                                '&:hover': {
                                    backgroundColor: colors.blueAccent[300],
                                    boxShadow: 'none',
                                },

                            },


                        }}
                    >
                        <LoadingButton
                            size="small"
                            onClick={handleProcessEclipse}
                            endIcon={icon === 'send' ? <SendIcon /> : icon === 'save' ? <SaveIcon /> : <WarningAmberIcon />}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            className="button"
                        >
                            <span>{eclipseResponse}</span>
                        </LoadingButton>
                        <p className="eclipse-response">{idResponse}</p>
                        <Snackbar
                            open={openSnack}
                            onClose={() => setOpenSnack(false)}
                            autoHideDuration={2000}
                            message="Copied to clipboard"
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50px',
                            }}
                        >
                            <p style={{ textAlign: 'center' }}>{eclipseLabel}</p>
                        </div>
                    </Box>
                </div>
            </ThemeProvider>
        </>
    );
};

export default SubmitComponent;