import { Box, useTheme, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import {
    gridClasses,
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
} from "@mui/x-data-grid";
import SubmitComponent from "../components/SubmitEclipse/SubmitComponent";
import { tokens } from "../components/Grid/Theme/theme";
import { ConditionalRenderBox } from "../components/Grid/ConditionalRenderBox";
import { DropdownSearch } from "../components/Grid/DropdownSearch";
import { AddDelete } from "../components/Grid/AddDelete"
import { QuantityComponent } from "../components/Grid/QuantityComponent"
import { TagComponent } from "../components/Grid/TagComponent"

import MenuItem from "@mui/material/MenuItem";
import { darken, lighten } from "@mui/material/styles";

const getJson = (apiRef) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
    // Format the data. Here we only keep the value
    const dataJson = filteredSortedRowIds.map((id) => {
        const row = {};
        visibleColumnsField.forEach((field) => {
            row[field] = apiRef.current.getCellParams(id, field).value;
        });
        return row;
    });
    // Stringify with some indentation
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
    return JSON.stringify(dataJson, null, 2);
};

const exportBlob = (blob, filename) => {
    // Save the blob in a json file
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();

    setTimeout(() => {
        URL.revokeObjectURL(url);
    });
};

const JsonExportMenuItem = (props) => {
    const apiRef = useGridApiContext();

    const { hideMenu } = props;

    return (
        <MenuItem
            onClick={() => {
                const jsonString = getJson(apiRef);
                const blob = new Blob([jsonString], {
                    type: "text/json",
                });

                exportBlob(blob, "DataGrid_demo.json");

                // Hide the export menu after the export
                hideMenu?.();
            }}
        >
            Export JSON
        </MenuItem>
    );
};

JsonExportMenuItem.propTypes = {
    hideMenu: PropTypes.func,
};

const csvOptions = { delimiter: ";" };

const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem options={csvOptions} />
        <JsonExportMenuItem />
    </GridToolbarExportContainer>
);

const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
        <CustomExportButton />
    </GridToolbarContainer>
);

const Grid = (props) => {
    var { branchLoaded, idLoaded, fileSelected, setFileSelected, submitAtempt, setSubmitAtempt, eclipseSubmited, setBranchLoaded, setIdLoaded, setEclipseSubmited } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    var { data, branch } = useAppSelector((state) => state.userReducer);
    var [post, setPost] = useState();
    const dispatch = useAppDispatch();
    var [rows, setRows] = useState(data);

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 100,
        page: 0,
    });

    function handleReset() {
        console.log("in handleReset");
        setRows([]);
        setPaginationModel({ pageSize: 100, page: 0 });
    }

    function getDescription(params) {
        //console.log(`params.row.descriptionIndex: ${params.row.descriptionIndex}`);
        //console.log(`paramas.row.description: ${params.row.description}`);
        const descriptionValues = params.row.description;
        const tagValue = params.row.tag;
        if (descriptionValues.length === 0 && tagValue === '') {
            var descriptionVal = 'Product not recognized, needs attention';
        } else {
            const descriptionIndex = params.row.descriptionIndex;
            var descriptionVal = descriptionValues[descriptionIndex];
        }
        return descriptionVal
    }

    function getEclipseID(params) {
        //console.log(`params.row.descriptionIndex: ${params.row.descriptionIndex}`);
        //console.log(`paramas.row.description: ${params.row.description}`);
        const eclipseValues = params.row.eclipse_id;
        const descriptionIndex = params.row.descriptionIndex;
        return eclipseValues[descriptionIndex];
    }

    function getManufacturer(params) {
        const manufacturerValues = params.row.manufacturer;
        const descriptionIndex = params.row.descriptionIndex;
        return manufacturerValues[descriptionIndex];
    }

    useEffect(() => {
        setRows(data);
        //console.log("ROWS ROWS ROWS:", rows);
    }, [data]);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 80,
            hide: "true",
            editable: true
        },
        {
            field: "tag",
            headerName: "TAG",
            width: 120,
            type: "singleSelect",
            cellClassName: "name-column--cell",
            renderCell: (params) => TagComponent({ ...params, rows, setRows }),
            editable: false,
        },
        {
            field: "quantity",
            headerName: "QTY",
            type: "number",
            width: 120,
            headerAlign: "center",
            renderCell: (params) => QuantityComponent({ ...params, rows, setRows }),
            align: "center",
            editable: false,
        },
        {
            field: "manufacturer",
            headerName: "MFG",
            renderCell: (params) => ConditionalRenderBox({ ...params }),
            valueGetter: getManufacturer,
            width: 120,
            headerAlign: "center",
            align: "center",
            editable: false,
            type: "string",
        },
        {
            field: "eclipse_id",
            headerAlign: "center",
            align: "center",
            valueGetter: getEclipseID,
            width: 120,
            headerName: "ECLIPSE ID",
            editable: false,
        },
        {
            field: "model",
            type: "integer",
            headerAlign: "center",
            headerName: "MODEL",
            align: "center",
            width: 200,
            editable: false,
            renderCell: (params) => DropdownSearch({ ...params, rows, setRows }),
            // renderEditCell: (params) =>RatingEdit({...params, setRows, rows} ),
        },
        {
            field: "description",
            headerName: "DESCRIPTION",
            width: 470,
            valueGetter: getDescription,
            renderCell: (params) => ConditionalRenderBox({ ...params }),
            editable: false,
            type: "string",
            //renderEditCell: (params) => <EditTextarea {...params} />,
        },
        {
            field: "insert",
            headerName: "ADD / DELETE",
            width: 140,
            sortable: false,
            renderCell: (params) => AddDelete({ ...params, rows, setRows }),
        },
    ];

    // CONDITIONAL RENDER BY ROW
    const getBackgroundColor = (color, mode) =>
        mode === "dark" ? darken(color, 0.0) : lighten(color, 0.0);

    const getHoverBackgroundColor = (color, mode) =>
        mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

    const buttonStyle = {
        marginLeft: 2 + 2 + "rem",
        fontSize: Math.random() > 0.5 ? "20px" : "40px",
        padding: "20px",
        color: "white",
    };

    return (
        <>
            <div className="grid-container">

                <Box sx={{ width: '100%' }}>
                    <Button size="small" onClick={handleReset}>
                        RESET
                    </Button>
                    <Box
                        m="20px 0 0 0"
                        id='grid'
                        sx={{
                            width: "auto",
                            "& .single-entry-row": {
                                bgcolor: (theme) =>
                                    getBackgroundColor(colors.grey[400], theme.palette.mode),
                                "&:hover": {
                                    bgcolor: (theme) =>
                                        getHoverBackgroundColor(
                                            theme.palette.warning.main,
                                            theme.palette.mode
                                        ),
                                },
                            },
                            "& .multiple-entry-row": {
                                bgcolor: (theme) =>
                                    getBackgroundColor(colors.grey[900], theme.palette.mode),
                                "&:hover": {
                                    bgcolor: (theme) =>
                                        getHoverBackgroundColor(
                                            theme.palette.warning.main,
                                            theme.palette.mode
                                        ),
                                },
                            },
                            // STYLE
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.grey[900],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[300],
                                color: colors.grey[900],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: colors.grey[900],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },

                        }}
                    >
                        <DataGrid
                            autoHeight
                            getRowId={(row) => row.id}
                            initialState={{
                                ...data.initialState,
                                pagination: { paginationModel: { pageSize: 5, page: 0 } },
                            }}
                            pageSizeOptions={[100, 50, 25]}

                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}

                            getRowClassName={(params) => {
                                if (params.row.tag !== '' && params.row.tag !== ' ') {
                                    return "single-entry-row";
                                } else {
                                    return "multiple-entry-row";
                                }
                            }}
                            // checkboxSelection
                            rows={rows}
                            columns={columns}
                            getRowHeight={() => "auto"}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </Box>
                </Box>
            </div>

            <div className="row justify-content-center">
                <div className="col-6 col-md-4 col-lg-3 ">
                    <SubmitComponent
                        branchLoaded={branchLoaded}
                        idLoaded={idLoaded}
                        fileSelected={fileSelected}
                        setFileSelected={setFileSelected}
                        submitAtempt={submitAtempt}
                        setSubmitAtempt={setSubmitAtempt}
                        eclipseSubmited={eclipseSubmited}
                        setEclipseSubmited={setEclipseSubmited}
                        setBranchLoaded={setBranchLoaded}
                        setIdLoaded={setIdLoaded}
                    />
                </div>
            </div>


        </>
    );
};

export default Grid;
