import SearchField from "../components/SearchField/SearchField";
import CategorizationResult from "../components/CategorizationResult/CategorizationResult";

import ReeceBanner from "../assets/images/reece-logo-small.png";

import "./styles/Search.css";
import SearchResult from "../components/SearchResult/SearchResult";

const Search = () => {
  return (
    <div className="search-wrapper">
      <div>
        <img src={ReeceBanner} alt="Reece Logo" width={230} height={105} />
      </div>

      <SearchField />

      <CategorizationResult />

      <SearchResult />
    </div>
  );
};

export default Search;
