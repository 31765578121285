import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { categorizeText } from "../../store/reducers/UserActionCreator";

import "./SearchField.css";

const SearchField = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.userReducer);

  const [searchText, setSearchText] = useState<string>("");

  return (
    <div className="search-field">
      <input type="text" onChange={(e) => setSearchText(e.target.value)} />
      <button
        className="search-btn"
        disabled={isLoading}
        onClick={() => searchText && dispatch(categorizeText(searchText))}
      >
        Search
      </button>
    </div>
  );
};

export default SearchField;
