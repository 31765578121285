import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestTypes } from "../../types/requestsTypes";

import {
  categorizeText,
  getMetrics,
  sendFile,
  sendFileEclipse,
  sendReport,
  getPost,
  addRow,
  deleteRow,
  changeQuantity,
  selectModel,
  setBranchCookie,
  changeTag,
  resetData,
  insertData,
  resetValues,
} from "./UserActionCreator";

interface UserState {
  csvData: string;
  data: any;
  oldData: any;
  notesObject: any;
  formData: FormData;
  formEclipseData: FormData;
  eclipseOutput: string;
  formData_ImageFile: FormData;
  categorizedResult: any;
  postResult: any;
  isLoading: boolean;
  errorMessage: boolean;
  userError: string;
  userUpdate: string;
  userCode: number;
  requestType: RequestTypes;
  scrollTo: string;
  metrics: any;
  branch: number;
  eclipseData: any;
  eclipseLabel: string;
  renderCounter: number;
}

const initialState: UserState = {
  categorizedResult: {},
  postResult: {},
  csvData: "",
  data: [],
  oldData: [],
  notesObject: {},
  formData: new FormData(), //file
  formEclipseData: new FormData(),
  eclipseOutput: '',
  formData_ImageFile: new FormData(), //file
  errorMessage: false,
  isLoading: false,
  userError: "",
  userUpdate: '',
  userCode: 0,
  requestType: RequestTypes.none,
  scrollTo: "",
  metrics: {},
  branch: 0,
  eclipseData: [],
  eclipseLabel: '',
  renderCounter: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setFormData(state, action: PayloadAction<FormData>) {
      state.formData = action.payload;
    },
    setEclipseData(state, action: PayloadAction<FormData>) {
      state.formEclipseData = action.payload;
    },
    setEclipseLabel(state, action: PayloadAction<FormData>) {
      state.formEclipseData = action.payload;
    },
    setFormDataImageFile(state, action: PayloadAction<FormData>) {
      state.formData_ImageFile = action.payload;
    },
    setUserCode(state, action: PayloadAction<number>) {
      state.userCode = action.payload;
    },
    setScrollSection(state, action: PayloadAction<string>) {
      state.scrollTo = action.payload;
    },
    setCsvData(state, action: PayloadAction<string>) {
      state.csvData = action.payload;
    },
    setData(state, action: PayloadAction<string>) {
      state.data = action.payload;
    },
    setPost(state, action: PayloadAction<string>) {
      state.data = action.payload;
    },
    setBranch(state, action: PayloadAction<string>) {
      state.data = action.payload;
    },
    setUsername(state, action: PayloadAction<string>) {
      state.data = action.payload;
    },

  },
  extraReducers: {
    //Processing file
    [sendFile.fulfilled.type]: (
      state,
      action: PayloadAction<{
        message: string;
        code: number;
        csvData: string,
        data: any,
        renderCounter: number,
        notesObject: any
      }>
    ) => {
      state.csvData = action.payload.csvData;
      state.data = action.payload.data;
      state.userError = action.payload.message;
      state.userCode = action.payload.code;
      state.isLoading = false;
      state.renderCounter = action.payload.renderCounter;
      state.eclipseLabel = "";
      state.errorMessage = false;

    },
    [sendFile.pending.type]: (state) => {
      state.isLoading = true;
      state.requestType = RequestTypes.fileProcessing;
      state.userCode = 0;
    },
    [sendFile.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.userError = action.payload.message;
      state.userCode = action.payload.code
      state.isLoading = false;
      state.errorMessage = true;

    },

    //Delete Branch
    [setBranchCookie.fulfilled.type]: (
      state,
      action: PayloadAction<{
        branch: number;
        message: string;
        code: number;
      }>
    ) => {
      state.branch = action.payload.branch;
      state.userCode = action.payload.code;
      state.isLoading = false;
      state.errorMessage = false;

    },
    [setBranchCookie.pending.type]: (state) => {
      state.isLoading = true;
      state.requestType = RequestTypes.fileProcessing;
      state.userCode = 0;
      state.isLoading = false;

    },
    [setBranchCookie.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.userError = action.payload.message;
      state.userCode = 0;
      state.isLoading = false;
      state.errorMessage = true;

    },

    //Processing ECLIPSE file
    [sendFileEclipse.fulfilled.type]: (
      state,
      action: PayloadAction<{
        eclipseLabel: string,
        eclipseData: any,
      }>

    ) => {
      state.eclipseData = action.payload.eclipseData;
      state.eclipseLabel = action.payload.eclipseLabel;
      state.errorMessage = false;
      state.isLoading = false;
    },
    [sendFileEclipse.pending.type]: (state) => {
      state.isLoading = true;
      state.requestType = RequestTypes.fileProcessing;
      state.userCode = 0;
    },
    [sendFileEclipse.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.userError = action.payload.message;
      state.userCode = action.payload.code ? action.payload.code : 500;
      state.isLoading = false;
      state.errorMessage = true;

    },

    //Sending report
    [sendReport.fulfilled.type]: (state, _) => {
      state.userError = "";
      state.userCode = 200;
      state.isLoading = false;
    },
    [sendReport.pending.type]: (state) => {
      state.isLoading = true;
      state.requestType = RequestTypes.sendingReport;
      state.userCode = 0;
    },
    [sendReport.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.userError = action.payload.message;
      state.userCode = action.payload.code;
      state.isLoading = false;
      state.errorMessage = true;

    },

    //Get metrics
    [getMetrics.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.userError = "";
      state.isLoading = false;
      state.metrics = action.payload;
      state.errorMessage = false;
    },
    [getMetrics.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getMetrics.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.isLoading = false;
      state.errorMessage = true;

    },

    //categorizeText
    [categorizeText.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.userError = "";
      state.isLoading = false;
      state.categorizedResult = action.payload;
    },
    [categorizeText.pending.type]: (state) => {
      state.isLoading = true;
    },
    [categorizeText.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.categorizedResult = [];
      state.isLoading = false;
      state.errorMessage = true;

    },

    //get
    [getPost.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.userError = "";
      state.isLoading = false;
      state.postResult = action.payload;
      state.errorMessage = false;
      state.data = action.payload.data;
    },
    [getPost.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getPost.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.postResult = [];
      state.isLoading = false;
      state.errorMessage = true;

    },


    //INSERT
    [insertData.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.userError = "";
      state.isLoading = false;
      state.data = action.payload.data;
      state.oldData = action.payload.oldData;
      state.userUpdate = action.payload.message;
      state.renderCounter = action.payload.renderCounter;
      state.errorMessage = false;
    },
    [insertData.pending.type]: (state) => {
      state.isLoading = true;
    },
    [insertData.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.postResult = [];
      state.isLoading = false;
      state.errorMessage = true;

    },



    //get
    [resetData.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.eclipseLabel = "";
      state.userError = "";
      state.isLoading = false;
      state.data = action.payload.data;
      state.oldData = action.payload.oldData;
      state.errorMessage = false;
    },
    [resetData.pending.type]: (state) => {
      state.isLoading = true;
    },
    [resetData.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.postResult = [];
      state.isLoading = false;
      state.errorMessage = true;

    },

    //get
    [resetValues.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.userError = "";
      state.isLoading = false;
      state.data = action.payload.data;
      state.oldData = action.payload.oldData;
      state.errorMessage = false;

    },
    [resetValues.pending.type]: (state) => {
      state.isLoading = true;
    },
    [resetValues.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.postResult = [];
      state.isLoading = false;
      state.errorMessage = true;
    },



    //get
    [addRow.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.userError = "";
      state.isLoading = false;
      state.data = action.payload.data;
      state.errorMessage = false;

    },
    [addRow.pending.type]: (state) => {
      state.isLoading = true;
    },
    [addRow.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.postResult = [];
      state.isLoading = false;
      state.errorMessage = true;

    },

    //get
    [selectModel.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.userError = "";
      state.userUpdate = action.payload.message;
      state.data = action.payload.data;
      state.isLoading = false;
      state.errorMessage = false;

    },
    [selectModel.pending.type]: (state) => {
      state.isLoading = true;

    },
    [selectModel.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.isLoading = false;
    },

    //deleRow
    [deleteRow.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.userError = "";
      state.isLoading = false;
      state.data = action.payload.data;
      state.errorMessage = false;
    },
    [deleteRow.pending.type]: (state) => {
      state.isLoading = true;
    },
    [deleteRow.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.isLoading = false;
      state.errorMessage = true;
    },

    //changeQuantity
    [changeQuantity.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.userError = "";
      state.isLoading = false;
      state.data = action.payload.data;
      state.errorMessage = false;
    },
    [changeQuantity.pending.type]: (state) => {
      state.isLoading = true;
    },
    [changeQuantity.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.isLoading = false;
      state.errorMessage = true;
    },

    //changeTag
    [changeTag.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.userError = "";
      state.isLoading = false;
      state.data = action.payload.data;
      state.errorMessage = false;
    },
    [changeTag.pending.type]: (state) => {
      state.isLoading = true;
    },
    [changeTag.rejected.type]: (
      state,
      action: PayloadAction<{ message: string; code: number }>
    ) => {
      state.isLoading = false;
      state.errorMessage = true;
    },
  },

});

export const {
  setFormData,
  setFormDataImageFile,
  setEclipseData,
  setUserCode,
  setScrollSection,
  setCsvData,
  setBranch,
  setUsername,
  setData,
  setPost,
} = userSlice.actions;

export default userSlice.reducer;
