import Header from "../components/Header/Header";
import HeaderGrid from "../components/Header/HeaderGrid";
import ReportForm from "../components/ReportForm/ReportForm";
import UploadFrom from "../components/UploadForm/UploadForm";
import GridBase from "./GridBase";
import { useState } from 'react'

const Home = () => {

    var [branchLoaded, setBranchLoaded] = useState<boolean>(false);
    var [idLoaded, setIdLoaded] = useState<boolean>(false);
    var [idLoaded, setIdLoaded] = useState<boolean>(false);
    var [fileSelected, setFileSelected] = useState<boolean>(false);
    var [eclipseSubmited, setEclipseSubmited] = useState<boolean>(false);
    var [submitAtempt, setSubmitAtempt] = useState<boolean>(false);
    var [restoreGrid, setRestoreGrid] = useState<boolean>(false);


    return (
        <div className="container overflow-hidden">

            <div className="row">
                <div className="col-6 col-md-6 col-lg-6">
                    <Header />
                </div>
            </div>

            <div className="row ">
                <div className="col-12 col-md-12 col-lg-8">
                    <HeaderGrid
                        branchLoaded={branchLoaded}
                        setBranchLoaded={setBranchLoaded}
                        setIdLoaded={setIdLoaded}
                    />
                </div>
                <div className="col-12 col-md-12 col-lg-4">
                    <UploadFrom
                        setFileSelected={setFileSelected}
                        setBranchLoaded={setBranchLoaded}
                        setIdLoaded={setIdLoaded}
                        setEclipseSubmited={setEclipseSubmited}
                        setSubmitAtempt={setSubmitAtempt}
                        setRestoreGrid={setRestoreGrid}
                        restoreGrid={restoreGrid}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                    <GridBase
                        branchLoaded={branchLoaded}
                        idLoaded={idLoaded}
                        fileSelected={fileSelected}
                        setFileSelected={setFileSelected}
                        submitAtempt={submitAtempt}
                        setSubmitAtempt={setSubmitAtempt}
                        eclipseSubmited={eclipseSubmited}
                        setEclipseSubmited={setEclipseSubmited}
                        setBranchLoaded={setBranchLoaded}
                        setIdLoaded={setIdLoaded}
                        setRestoreGrid={setRestoreGrid}
                        restoreGrid={restoreGrid}

                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-12 col-lg-12 mt-10" >
                </div>
            </div>



            <div className="row   justify-content-around  ">
                <div className="col-6 col-md-6 col-lg-6">
                    <ReportForm />
                </div>
            </div>


        </div>
    );
};

export default Home;
