import React, { FC } from "react";
import { Spinner as BootstrapSpinner } from "react-bootstrap";

import "./Spinner.css";

interface SpinnerProps {
  show: boolean;
  text: string;
}

const Spinner: FC<SpinnerProps> = ({ show, text }) => {
  return (
    <div className={show ? "spinner-container" : "spinner-container hidden"}>
      <BootstrapSpinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </BootstrapSpinner>

      <p>{text}</p>
    </div>
  );
};

export default Spinner;
