import React, { FC, useEffect, useState } from "react";
import { X } from "react-bootstrap-icons";
import Button from "../Button/Button";
import FileIcon from "../Icons/FileIcon";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { sendFile, resetData, insertData } from "../../store/reducers/UserActionCreator";

import "./FileProcessing.css";

interface FileInfoProps {
  fileData: {
    name: string;
    size: number;
  };
  show: boolean;
  onHide: any;
  setRows:any;
  restoreGrid:any;
  setRestoreGrid:any;
  
}

const FileProcessing: FC<FileInfoProps> = ({
  fileData,
  onHide,
  show,
  setRows,
  setRestoreGrid,
  restoreGrid
}) => { 
  const dispatch = useAppDispatch();
  const {formData, data, renderCounter, setData, oldData, isLoading} = useAppSelector((state: { userReducer: any }) => state.userReducer);

  const handleProcessBlueprint = async () => {
    if (formData.has("file")) {

      await dispatch(
        sendFile({
          fileData: formData,
          parsing_strategy: 3,
          localTest: false,
          renderCounter: renderCounter,
        })
      );
    //This has to be done twice to reset the values
     setRestoreGrid((prevValue:Boolean) => !prevValue)
     setRestoreGrid((prevValue:Boolean) => !prevValue)

    }
  };


  if (show) {
    return (
      <div className="file-processing-wrapper">
        <div className="file-info-container">
          <div className="file-info-content">
            <FileIcon />
            <p className="file-name">{fileData.name}</p>
            <p>{(fileData.size / 1048576).toFixed(1)} MB</p>
          </div>

          <X className="close-btn" width={25} height={25} onClick={onHide}></X>
        </div>

        <div>
          <Button
            status="success"
            text="Process Blueprint"
            clickHandler={handleProcessBlueprint}
          />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default FileProcessing;
