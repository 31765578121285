import React, { FC } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAppSelector } from "../../hooks/redux";
import QuestionMarkIcon from "../Icons/QuestionMarkIcon";

import "./TextArea.css";

interface TextAreaProps {
  title: string;
  changeHandler: (e: any) => void; 
}

const TextArea: FC<TextAreaProps> = ({ title, changeHandler }) => {
  const { isLoading } = useAppSelector((state) => state.userReducer);

  return (
    <Form.Group className="text_area-container mb-3">
      <div className="text_area-header">
        <p className="text_area-title">{title}</p>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip className="question-tooltip">
              Use as much detail as possible to describe the issue
            </Tooltip>
          }
        >
          <button className="tooltip-btn">
            <QuestionMarkIcon />
          </button>
        </OverlayTrigger>
      </div>

      <Form.Control
        className="text-area"
        as="textarea"
        rows={4}
        placeholder="Something went wrong with this file"
        onChange={changeHandler}
        disabled={isLoading}
      />
    </Form.Group>
  );
};

export default TextArea;
