import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";

import Button from "../Button/Button";
import Spinner from "../Spinner/Spinner";
import FileProcessing from "../FileInfo/FileProcessing";
import UploadIcon from "../Icons/UploadIcon";
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import SuccessAlert from "../SuccessAlert/SuccessAlert";

import "./UploadFrom.css";
import {
  setFormData,
  setFormDataImageFile,
} from "../../store/reducers/UserSlice";
import { sendFile, resetData } from "../../store/reducers/UserActionCreator";
import { RequestTypes } from "../../types/requestsTypes";

const UploadFrom = (props:any) => {
    var {setFileSelected, setRows, setEclipseSubmited, setSubmitAtempt, setRestoreGrid, restoreGrid} = props

  const dispatch = useAppDispatch();
  const { isLoading, userCode, userError , csvData, data, formData, requestType, setData } =useAppSelector((state: { userReducer: any }) => state.userReducer);

  const [showFileProcessing, setShowFileProcessing] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [fileData, setFileData] = useState<any>({
    name: "",
    size: 0,
  });
  const [oldData, setOldData] = useState<any>({});
  
  const [officeIsChecked, setOfficeIsChecked] = useState<boolean>(false);

  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    //console.log("MODIFIED: useEffect - csvData: ", csvData)
    //console.log("MODIFIED: useEffect - data: ", data)

  }, [csvData]);

  useEffect(() => {
    if (
      !isLoading &&
      requestType === RequestTypes.fileProcessing &&
      ![0, 200, 201, 202].includes(userCode)
    ) {
      setShowErrorAlert(true);
    }

    if (isLoading) {
      setShowErrorAlert(false);
      console.log(alert)
    }
  }, [isLoading]);

  const handleFileInput = async(e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const tokens = e.target.files[0].name.split(".");

      if (!["jpg", "png", "pdf", "PNG"].includes(tokens[tokens.length - 1])) return;

      setFileData({
        name: e.target.files[0].name.split(".").slice(0, -1).join("."),
        size: e.target.files[0].size,
      });

      const newFormData = new FormData();
      newFormData.append("file", e.target.files[0]);
      setShowFileProcessing(true);

      dispatch(setFormData(newFormData));
      const formDataImageFile = new FormData();
      formDataImageFile.append("image_file", e.target.files[0]);
      dispatch(setFormDataImageFile(formDataImageFile));
      var resetResponse:any =await dispatch(
        resetData({
        oldData: data,
        })
    );        
    setFileSelected(true)
    setEclipseSubmited(false)
    setSubmitAtempt(false)

    await new Promise(r => setTimeout(setFileSelected(true), 1000));
    }
  };

  return (
    <div className="file_upload-wrapper">

      <div className="file_upload-container">
        <div className="file_upload-container-info">
          <UploadIcon />

          <div className="file_upload-container-info-description">
            <p>Drag and drop file here</p>
            <p className="file_upload-wrapper-text_small">
              Limit 200MB per file • PDF, PNG, JPEG, JPG
            </p>
          </div>
        </div>

        <Button forAttr="file" text="Browse files" />
        <input
          ref={fileInput}
          className="file-input"
          id="file"
          type="file"
          accept=".pdf,.jpeg,.png, .PNG"
          onChange={handleFileInput}
        />
      </div>

      <FileProcessing
      
        setRows={setRows}
        fileData={fileData}
        show={showFileProcessing}
        setRestoreGrid = {setRestoreGrid}
        restoreGrid = {restoreGrid}

        onHide={() => {
          setShowFileProcessing(false);
          dispatch(setFormData(new FormData()));
          dispatch(setFormDataImageFile(new FormData()));
          if (fileInput.current) fileInput.current.value = "";}}
      />

      <Spinner
        show={isLoading && requestType === RequestTypes.fileProcessing}
        text="Processing Blueprint..."
      />

      <ErrorAlert
        show={showErrorAlert}
        text={
          userError ??
            "Error processing blueprint. Please let us know so we can fix it"
        }
      />

    </div>
  );
};

export default UploadFrom;
