import React, { FC } from "react";

import "./WarningAlert.css";

interface WarningAlertProps {
  text: string;
  show: boolean;
}

const WarningAlert: FC<WarningAlertProps> = ({ show, text }) => {
  if (show) {
    return (
      <div className="warning-wrapper">
        <p>💡 {text}</p>
      </div>
    );
  } else {
    return <></>;
  }
};

export default WarningAlert;
