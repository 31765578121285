import React, { FC } from "react";

import './SuccessAlert.css';

interface SuccessAlertProps {
    text: string;
    show: boolean;
}

const SuccessAlert: FC<SuccessAlertProps> = ({ show, text }) => {
    if (show) {
        return (
            <div className="success-wrapper">
                <p>{text}</p>
            </div>
        );
    } else {
        return <></>;
    }
}

export default SuccessAlert;