import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../hooks/redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./Header.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Cookies from 'universal-cookie';
import branchData from "../../data/brachData.json";
import { setBranchCookie, setUsernameCookie } from "../../store/reducers/UserActionCreator";
import { useAppDispatch } from "../../hooks/redux";
import { TextField, Grid } from "@mui/material";
// import { Grid } from "react-bootstrap-icons";

function GetBranchCookie() {
    const cookies: any = new Cookies();
    const cookie: number = cookies.get('branch');
    if (cookie === undefined) {
        return 0 //'Estimating Office'
    } else {
        return cookie
    }
}

function GetUsernameCookie() {
    const cookies: any = new Cookies();
    const cookie: string = cookies.get('username');
    if (cookie === undefined) {
        return '' //'Estimating Office'
    } else {
        return cookie
    }
}

const HeaderGrid = (props: any) => {
    var { setBranchLoaded } = props;
    const [branch, setBranch] = useState<any>('');
    const [username, setUsername] = useState<any>('');
    const [branchList, setBranchList] = useState<any>(branchData.branchInfo);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        var savedCookie: number = Number(GetBranchCookie());
        var savedUsername: string = String(GetUsernameCookie());
        console.log('savedUsername', savedUsername)
        if (savedCookie === 0) {
            console.log(`WARNING: No cookies detected for branch`);

        } else {
            //console.log('HeaderGrid/savedCookie ' , savedCookie)
            for (var i = 0; i < branchList.length; i++) {
                let branchElement = branchList[i]
                let branchCode = branchElement['location_code'];
                let branchName = branchElement['name'];

                if (branchCode === savedCookie) {
                    let selectedBranch = branchName;
                    setBranch(selectedBranch)
                    dispatch(
                        setBranchCookie({
                            branch: branchCode,
                        })
                    );
                }
            }
        }


        if (savedUsername === '') {
            console.log(`WARNING: No cookies detected for username`);

        } else {
            setUsername(savedUsername)
            dispatch(
                setUsernameCookie({
                    username: savedUsername,
                })
            );
        }

    }, []);

    const handleChangeBranch = async (event: any) => {
        const cookies = new Cookies();
        var branchChangeName: string = event.target.value;
        if (branchChangeName === 'Estimating Office') {
            console.log(`WARNING: You haven't select any branch`);
            cookies.set('branch', 0, { path: '/' });
            setBranchLoaded(false)
            var branchNumber = event.target.value;
            setBranch(branchNumber);

        } else {
            var selectedCode: number = 0;
            var branchNumber = event.target.value;
            // We need to find the code rather than the name of the branch
            for (var i = 0; i < branchList.length; i++) {
                let branchElement = branchList[i]
                let branchCode = branchElement['location_code'];
                let branchName = branchElement['name'];
                if (branchName === branchChangeName) {
                    selectedCode = branchCode;
                }
            }

            setBranch(branchNumber);
            cookies.set('branch', selectedCode, { path: '/' });
            // Save branch into app memory
            await dispatch(
                setBranchCookie({
                    branch: selectedCode,
                })
            );
            setBranchLoaded(true)

        }


    };

    const handleChangeUsername = async () => {
        const cookies = new Cookies();

        if (username === '') {
            console.log(`WARNING: You haven't entered an eclipse ID`);
            // Alert pop up window if required in the future
            // window.alert("Please Enter your Eclipse ID 😊")

        } else {
            cookies.set('username', username, { path: '/' });
            // Save branch into app memory
            await dispatch(
                setUsernameCookie({
                    username: username,
                })
            );

        }


    };

    return (
        <div>
            <div className="row branch-form align-baseline">
                <Grid container >
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Estimating Office</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select1"
                                value={branch}
                                label="Branch"
                                onChange={handleChangeBranch}>
                                {branchList.map((item: any) => (
                                    <MenuItem key={item.id} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} sx={{ paddingLeft: 3 }}>
                        <FormControl fullWidth>
                            <TextField
                                id="username"
                                label="Team Member Eclipse ID"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                onBlur={(e) => {
                                    handleChangeUsername();
                                }}
                            />

                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default React.memo(HeaderGrid);