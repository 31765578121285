import * as React from "react";
import { Box, TextField, Typography } from 
"@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { changeQuantity } from "../../store/reducers/UserActionCreator";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from 'reactstrap';

export function QuantityComponent(props) {
    var { data } = useAppSelector((state) => state.userReducer);
    var { id, field, value, colDef, rows, setRows } = props;
    const dispatch = useAppDispatch();
    const [alert, setAlert] = React.useState(false)
    const [qtyColor, setQtyColor] = React.useState('black')

    const isLetters = (str) => /^[A-Za-z]*$/.test(str);
    const modalStyles = {
        position: "absolute",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
    //ALERT AUXILIAR FUNCTIONS
    function closeAlert() {
        setAlert(false);
    }
    function isNumericString(str) {
        for (let i = 0; i < str.length; i++) {
            var test = Number(str[i])
          if (isNaN(test)) {
            return false;
          }
        }
        return true;
      }

    const  handleQty = async (qty) =>{
        var result = isNumericString(qty);
        if (result) {
            var quantityContent = {
                data:rows,
                quantity:Number(qty),
                selectedId:id
            }
            const response  = await dispatch(changeQuantity(quantityContent));
            setQtyColor('black')

            return qty
          }else{
            console.log('\n ***** ERROR *****')
            setAlert(true)
            setQtyColor('black')
            return ''

          }
        }

    return (
        <div>
            {value ==='' || value === ' '? (
                <Typography 
                        id={id} 
                        label={value} 
                        variant="outlined" 
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*', // Allow only numeric characters
                          }}
                        />
            ) 
            : (
                <React.Fragment>
                    <Box
                        width="100%"
                        p="3px"
                        display="flex"
                        justifyContent="right"
                        borderRadius="3px"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                                background: 'transparent'},
                            },
                            '& .MuiInputBase-input': {
                            background: 'transparent'

                            },
                        }}
                        >
                        <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*', // Allow only numeric characters
                            }}
                            defaultValue = {value}
                            inputProps={{ style: { color: qtyColor } }}
                            onKeyDown={(event) => {
                                //console.log("Key down: ", event.key);
                                setQtyColor('green')
                                event.stopPropagation();
                                if (event.key === "Enter") {
                                    handleQty(event.target.value);
                                    setQtyColor('black')
                                }
                            }}
                            onBlur={(event) => {handleQty(event.target.value);}}
                            />

                        <div>
                            <Modal isOpen={alert} style={modalStyles}>
                                <ModalHeader>
                                    Error: Please insert a numeric value.
                                </ModalHeader>
                                <ModalFooter>
                                    <Button color="secondary" onClick={closeAlert}>Close</Button>
                                </ModalFooter>
                            </Modal>
                        </div>

                        </Box> 
                </React.Fragment>
            )}
        </div>
    );
}

export default QuantityComponent;
