import { Box, Typography, useTheme } from "@mui/material";
import React from 'react';
import { tokens } from "./Theme/theme.js";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";

export  function ConditionalRenderBox(props) {
    const { id, field, value, colDef } = props;   
    const buttonElement = React.useRef(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);  
    const descriptionFirstElement = value;
  return (
    <div>
    {descriptionFirstElement === "PRODUCT NOT IDENTIFIED" ? 
        <Box
        width="100%"
        m="-1 auto"
        p="4px"
        ref={buttonElement}
        display="flex"
        justifyContent="center"
        backgroundColor={
            descriptionFirstElement=== "PRODUCT NOT IDENTIFIED"
            ? colors.redAccent[500]
            : colors.greenAccent[600]
        }
        borderRadius="3px"
        >
        {descriptionFirstElement === "PRODUCT NOT IDENTIFIED" && <AdminPanelSettingsOutlinedIcon />}
            <Typography color={colors.grey[99]} sx={{ ml: "5px" }}>
                {descriptionFirstElement}
            </Typography>
        </Box> 
      : 
      <Typography  defaultValue ={value}>
            {value}
      </Typography>

        }  
</div>
  )}

  export default ConditionalRenderBox

