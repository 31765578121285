import Table from "react-bootstrap/Table";

import "./SearchResult.css";

const SearchResult = () => {
  return (
    <div className="search-result-wrapper">
      <p className="mb-3">
        <b>SEARCH RESULT</b>
      </p>

      <Table className="search-table" responsive bordered>
        <thead>
          <tr>
            <th>MODEL #</th>
            <th>DESCRIPTION</th>
            <th>IMAGE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="borderless-right">LHARDK20</td>
            <td className="borderless">Info</td>
            <td>Image</td>
          </tr>
          <tr>
            <td className="borderless-right">LHARDK20</td>
            <td className="borderless">Info</td>
            <td>Image</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default SearchResult;
