import React from 'react';
import Grid from "./Grid"

const GridBase = (props) => {
  var { idLoaded, branchLoaded, fileSelected, setFileSelected, submitAtempt, setSubmitAtempt, eclipseSubmited, setBranchLoaded, setIdLoaded, setEclipseSubmited, setRestoreGrid, restoreGrid } = props;
  // Function to conditionally render components based on the value of 'restoreGrid'
  const renderComponent = () => {
    if (restoreGrid) {
      return
      <div>

      </div>
    } else {
      return <Grid
        branchLoaded={branchLoaded}
        idLoaded={idLoaded}
        fileSelected={fileSelected}
        setFileSelected={setFileSelected}
        submitAtempt={submitAtempt}
        setSubmitAtempt={setSubmitAtempt}
        eclipseSubmited={eclipseSubmited}
        setEclipseSubmited={setEclipseSubmited}
        setBranchLoaded={setBranchLoaded}
        setIdLoaded={setIdLoaded}
      />;
    }
  };

  return (
    <div>
      {renderComponent()}
    </div>
  );
};

export default GridBase;
