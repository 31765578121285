import { useAppSelector } from "../../hooks/redux";
import "./CategorizationResult.css";

const CategorizationResult = () => {
  const { categorizedResult, isLoading } = useAppSelector((state) => state.userReducer);

  return (
    <div className="categorization-wrapper">
      <p>
        <b>TEXT CATEGORIZATION RESULT</b>
        {isLoading && ' loading...'}
      </p>
      {Object.keys(categorizedResult).length > 0 && (
        <div className="category">
          {Object.keys(categorizedResult).map((key, index) => (
            <div className="category-item">
              <p>{key}</p>
              <p className="category-item-text">
                {
                  (index = 0
                    ? categorizedResult[key].charAt(0).toUpperCase() +
                      categorizedResult[key].slice(1)
                    : categorizedResult[key].toLowerCase())
                }
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategorizationResult;
