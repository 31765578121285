import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { sendReport } from "../../store/reducers/UserActionCreator";
import { setUserCode } from "../../store/reducers/UserSlice";
import { RequestTypes } from "../../types/requestsTypes";
import Button from "../Button/Button";
import ErrorAlert from "../ErrorAlert/ErrorAlert";

import Select from "../Select/Select";
import Spinner from "../Spinner/Spinner";
import SuccessAlert from "../SuccessAlert/SuccessAlert";
import TextArea from "../TextArea/TextArea";
import WarningAlert from "../WarningAlert/WarningAlert";

import "./ReportForm.css";

const ReportForm = () => {
  const dispatch = useAppDispatch();
  const { isLoading, formData_ImageFile, requestType, userCode } =
    useAppSelector((state) => state.userReducer);

  const errorTypes = useMemo(
    () => [
      {
        text: "Processed",
        value: "Processed",
      },
      {
        text: "Did not process",
        value: "Did not process",
      },
      {
        text: "Other issue",
        value: "Other issue",
      },
    ],
    []
  );

  const [summary, setSummary] = useState<string>(errorTypes[0].value);
  const [description, setDescription] = useState<string>("");
  const [errorAlert, setErrorAlert] = useState<{
    show: boolean;
    text: string;
  }>({
    show: false,
    text: "",
  });

  useEffect(() => {
    if (
      !isLoading &&
      requestType === RequestTypes.sendingReport &&
      ![0, 200, 201].includes(userCode)
    ) {
      setErrorAlert({
        show: true,
        text: "Sending the report failed",
      });
    }

    if (isLoading) {
      setErrorAlert({
        show: false,
        text: "",
      });
    }
  }, []);

  return (
    <div className="report-wrapper">
      <div className="report-header">
        <h1>Report Errors</h1>
        <p className="text-alt">Experiencing errors? Use the form below</p>
      </div>

      <div className="report-form">
        <WarningAlert
          show
          text="A file must be uploaded in order to submit the report"
        />
        <Select
          title="Select the error"
          qustionMarkText="What type of error did you encounter?"
          disabled={isLoading}
          options={errorTypes}
          changeHandler={(e: any) => setSummary(e.target.value)}
        />

        <TextArea
          title="Describe the error"
          changeHandler={(e) => setDescription(e.target.value)}
        />

        <Button
          text="Submit Error Report"
          clickHandler={() => {
            if (formData_ImageFile.has("image_file")) {
              setErrorAlert({
                show: false,
                text: "",
              });

              dispatch(
                sendReport({
                  summary,
                  description,
                  fileData: formData_ImageFile,
                })
              );
            } else {
              dispatch(setUserCode(0));
              setErrorAlert({
                show: true,
                text: "Please upload a file in order to submit a report",
              });
            }
          }}
        />

        <Spinner
          show={isLoading && requestType === RequestTypes.sendingReport}
          text="Uploading report, please wait"
        />

        <ErrorAlert show={errorAlert.show} text={errorAlert.text} />

        {[200, 201].includes(userCode) &&
          requestType === RequestTypes.sendingReport && (
            <>
              <SuccessAlert
                show={true}
                text="The report has been uploaded successfully"
              ></SuccessAlert>
            </>
          )}
      </div>
    </div>
  );
};

export default ReportForm;
