import * as React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from 'reactstrap';
import { Box, TextField, Typography } from
    "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { getPost, selectModel } from "../../store/reducers/UserActionCreator";
import { setData } from "../../store/reducers/UserSlice";
import { alpha, styled } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";

const filter = createFilterOptions();

export function DropdownSearch(props) {
    var { data } = useAppSelector((state) => state.userReducer);
    var { id, field, value, colDef, rows, setRows } = props;
    const [alert, setAlert] = React.useState(false)
    const [searchProduct, setSearchProduct] = React.useState('NO PRODUCT SEARCHED')
    const [open, toggleOpen] = React.useState(false);
    const [multipleEntryColor, setMultipleEntryColor] = React.useState('#00b2f1')

    const [model, setModel] = React.useState("");
    const dispatch = useAppDispatch();
    const [options, setOptions] = React.useState([]);

    const handleDropdowSelect = async (event) => {
        const valueSelected = event.target.value;
        setModel(valueSelected);
        const result = await dispatch(
            selectModel({
                rows: rows,
                id: id,
                valueSelected: valueSelected,
            }
            ));

        console.log(`result: ${result}`);
        setData(result.data);
        setRows(result.data);
        setMultipleEntryColor('#ffffffff')
        handleClose();
    };

    React.useEffect(() => {
        const modelOptions = enlistOptions(value);
        //console.log("modelOptions: ", modelOptions);
        setOptions(modelOptions);
    }, []);

    const enlistOptions = (value) => {
        var modelOptions = [];
        for (var i = 0; i < value.length; i++) {
            var variable = value[i];
            modelOptions.push({ product: variable });
        }
        //console.log("modelOptions:", modelOptions);
        return modelOptions;
    };

    const handleClose = () => {
        setDialogValue({
            product: "",
        });
        toggleOpen(false);
    };
    const [dialogValue, setDialogValue] = React.useState({
        product: "",
    });

    const CustomSelect = styled(Select)(() => ({
        "&.MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#00b2f1",
                backgroundColor: "#00b2f1",
            },
            "&:hover fieldset": {
                borderColor: "yellow",
            },
            "&.Mui-focused fieldset": {
                borderColor: "green",
            },
        },
    }));

    // Modifies model values to display description and manufacturer
    function getModelValue(value, i, id, rows) {
        var manufacturer = rows[id]['manufacturer'][i]
        var description = rows[id]['description'][i]

        var extendedValue = value + ' | ' + manufacturer + ' | ' + description
        return extendedValue
    }
    //ALERT AUXILIAR FUNCTIONS
    function closeAlert() {
        setAlert(false);
    }

    const modalStyles = {
        position: "absolute",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }

    const handlePostEnter = async (newValue) => {
        var sanitizeValue = encodeURIComponent(newValue);
        const result = await dispatch(
            getPost({
                searchData: sanitizeValue,
                localTest: false,
                data: data,
                id: id,
            }
            ));
        if (result.payload.message === 'search completed') {
            setRows(result.payload.data);
            setData(result.payload.data);
            setSearchProduct(sanitizeValue)
            handleClose();
        } else if (result.payload.message === 'Empty String') {
            console.log('empty search not performed');
            setSearchProduct(newValue)
        } else if (result.payload.message === 'search completed, nonstock item') {
            setSearchProduct(newValue)
        } else {
            setAlert(true)
            setSearchProduct(newValue)
        }
    };

    return (
        <div>
            {value === "" ? (
                <Typography 
                        id={id} 
                        label={value} 
                        variant="outlined" 
                />  
            ): value.length > 1 ? (
                    //<ClickAwayListener onClickAway={handleClickAway}>
                    <Box sx={{ minWidth: 180 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{value[0]}</InputLabel>

                            <Select
                                id="demo-simple-select"
                                value={model}
                                label="Select a model"
                                style={{ backgroundColor: multipleEntryColor, border: multipleEntryColor }}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em>'PLACEHOLDER'</em>;
                                    }
                                    return selected
                                }}
                                inputProps={{
                                    'aria-label': 'Without label',
                                    MenuProps: {
                                        MenuListProps: {
                                            sx: {
                                                backgroundColor: "#ccddffff",
                                            },
                                        },
                                    },
                                }}
                                onChange={handleDropdowSelect}
                            >
                                {value.map((e, i) => (
                                    <MenuItem key={i.toString()} value={e}>
                                        {getModelValue(e, i, id, rows)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    //</ClickAwayListener>

                ) : (
                    <React.Fragment>
                        <Box
                            width="100%"
                            p="3px"
                            display="flex"
                            justifyContent="right"
                            borderRadius="3px"
                            style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.0)', 
                              }}
                        >
                            <TextField
                                hiddenLabel
                                id="outlined-size-small"
                                defaultValue={value}
                                onKeyDown={(event) => {
                                    //console.log("Key down: ", event.key);
                                    event.stopPropagation();
                                    if (event.key === "Enter") {
                                        handlePostEnter(event.target.value);
                                    }
                                }}
                            />
                        </Box>

                        <div>
                            <Modal isOpen={alert} style={modalStyles}>
                                <ModalHeader>
                                    Error: We didn't get any matches for product {searchProduct}
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label for="usuario">Try with another product or retype your  search</Label>
                                    </FormGroup>
                                </ModalBody>

                                <ModalFooter>
                                    <Button color="secondary" onClick={closeAlert}>Close</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                        {/* END OF AUTOCOMPETE*/}
                    </React.Fragment>
                )}
        </div>
    );
}

export default DropdownSearch;
