import { useEffect, useState } from "react";

const Health = () => {
  const [date, setDate] = useState<string>("");

  useEffect(() => {
    const timer = setInterval(() => {
      const curDate = (new Date().toISOString().split('.'))[0];
      setDate(curDate.replace('T', ' '));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <>SCOUT {date}</>;
};

export default Health;
