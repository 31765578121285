import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import "./ErrorAlert.css";

interface ErrorAlertProps {
  text: string;
  show: boolean;
}

const ErrorAlert: FC<ErrorAlertProps> = ({ show, text }) => {
    const { errorMessage} = useAppSelector((state: { userReducer: any }) => state.userReducer);

    var [display, setDisplay] = useState(false);
    useEffect(() => {
      console.log(errorMessage)
      if(errorMessage){
        setDisplay(true);
      }else{
        setDisplay(false);
      }
    }, [errorMessage]);

  if (errorMessage) {
    return (
      <div className="error-wrapper">
        <p>{text}</p>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ErrorAlert;
