import React, { FC } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import QuestionMarkIcon from "../Icons/QuestionMarkIcon";

import "./Select.css";

interface SelectProps {
  title: string;
  options: any[];
  qustionMarkText?: string;
  disabled?: boolean;
  changeHandler?: any;
}

const Select: FC<SelectProps> = ({
  options,
  title,
  qustionMarkText,
  disabled,
  changeHandler
}) => {
  return (
    <div className="custom-select-wrapper">
      <div className="custom-select-header">
        <p className="custom-select-title">{title}</p>
        {qustionMarkText && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip className="question-tooltip">{qustionMarkText}</Tooltip>
            }
          >
            <button className="tooltip-btn">
              <QuestionMarkIcon />
            </button>
          </OverlayTrigger>
        )}
      </div>

      <Form.Select
        className="custom-select"
        aria-label="Default select example"
        disabled={disabled ? disabled : false}
        onChange={changeHandler}
      >
        {options.map((option, index) => (
          <option
            key={`select-option ${index} ${option.value}`}
            value={option.value}
          >
            {option.text}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default Select;
