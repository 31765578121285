import { Route, Routes } from "react-router";

import Home from "./pages/Home";
import Knowledge from "./pages/Knowledge";
import Health from "./pages/Health";
import Metrics from "./pages/Metrics";
import Search from "./pages/Search";
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::767032314216:role/RUM-Monitor-us-east-1-767032314216-8871030001961-Unauth",
    identityPoolId: "us-east-1:4a2353e8-70ee-4057-88be-51ef3d2b9784",
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance","errors","http"],
    allowCookies: true,
    enableXRay: true
  };

  const APPLICATION_ID: string = '2932b900-f0ab-496d-a0e3-b614f0394cdd';
  const APPLICATION_VERSION: string = '1.0.0';
  const APPLICATION_REGION: string = 'us-east-1';

  const awsRum: AwsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}


function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/health" element={<Health />} />
       <Route path="/searching" element={<Search />} /> 
      </Routes>
  );
}

export default App;
