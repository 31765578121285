import React, { FC } from "react";
import { useAppSelector } from "../../hooks/redux";

import "./Button.css";

interface ButtonProps {
  status?: string;
  text: string;
  clickHandler?: () => void;
  forAttr?: string;
}

const Button: FC<ButtonProps> = ({
  text,
  clickHandler,
  forAttr,
  status = "neutral",
}) => {
  const { isLoading } = useAppSelector((state) => state.userReducer);

  return (
    <label
      className={`custom-btn ${status === "success" ? "border-green" : ""}`}
      htmlFor={forAttr}
      onClick={() => {
        if (!isLoading && typeof clickHandler !== "undefined") clickHandler();
      }}
    >
      {text}
    </label>
  );
};

export default Button;
