import React, { useState, useEffect } from "react";
import { Box, TextField, Typography } from 
"@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { changeTag } from "../../store/reducers/UserActionCreator";

//CODE
export function TagComponent(props) {
    var { data } = useAppSelector((state) => state.userReducer);
    var { id, field, value, colDef, rows, setRows } = props;
    var [textColor, setTextColor] = useState('white');
    const dispatch = useAppDispatch();

    const   handleTag = async (params) =>{
        const tag = params;
        const tagContent = {
            data:rows,
            tag:tag,
            selectedId:id
        }
        const response  = await dispatch(changeTag(tagContent));
        setTextColor('white')
        return tag
    }

    return (
        <div>
            {value ==='' ?(
                <Typography 
                        id={id} 
                        label={value} 
                        variant="outlined" 

                        />
            ) :value === ' '? (
                                <React.Fragment>
                                <Box
                                    width="100%"
                                    p="4px"
                                    display="flex"
                                    justifyContent="right"
                                    borderRadius="3px"

                                    >
                                    <input
                                            defaultValue = {value}
                                            inputProps={{ style: { color: textColor } }}
                                        onKeyDown={(event) => {
                                            //console.log("Key down: ", event.key);
                                            event.stopPropagation();
                                            if (event.key === "Enter") {
                                                handleTag(event.target.value);
                                            }  
                                        }}
                                        onBlur={(event) => {handleTag(event.target.value); }}
                                        style={{
                                            background: "transparent",
                                            border: "none",
                                            outline: "none",
                                            textAlign: "left",
                                            color: "black",
                                            fontWeight: "bold"
                                          }}
                                    />
                                    </Box> 
                                        {/* END OF AUTOCOMPETE*/}
                                    </React.Fragment>
            ) 
            : (
                <React.Fragment>
            <Box
                width="100%"
                p="4px"
                display="flex"
                justifyContent="right"
                borderRadius="3px"
                sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                        background: 'transparent'
                      },

                    },
                    '& .MuiInputBase-input': {
                      background: 'transparent',
                      color: 'white', // Set text color to white
                      fontWeight: 'bold', // Set font weight to bold
                    },
                  }}
                >
                <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    defaultValue = {value}
                    onKeyDown={(event) => {
                        setTextColor('green')
                        //console.log("Key down: ", event.key);
                        event.stopPropagation();
                        if (event.key === "Enter") {
                            handleTag(event.target.value);

                        }
                    }}
                    inputProps={{ style: { color: textColor } }}
                    onBlur={(event) => {handleTag(event.target.value); }}
                />
                </Box> 
                    {/* END OF AUTOCOMPETE*/}
                </React.Fragment>
            )}
        </div>
    );
}

export default TagComponent;
