import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../hooks/redux";

import ScoutBanner from "../../assets/images/Scout-Web-Logo.jpg";
import ReeceBanner from "../../assets/images/reece-logo-small.png";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { errorCodes, noChangeErrorCodes } from "../../utils/errorCodes";

import { RequestTypes } from "../../types/requestsTypes";

import "./Header.css";

const Header = () => {
  const { userCode, requestType } = useAppSelector((state) => state.userReducer);
  const dimensions = useWindowDimensions();

  const progressBar = useRef<HTMLDivElement>(null);
  const [progressBarText, setProgressBarText] = useState<string>('');
  const [progressBarSubText, setProgressBarSubText] = useState<any>();
  const { csvData } = useAppSelector((state) => state.userReducer);
  // console.log("******** Grid/state/csvData:", csvData)

  useEffect(() => {
    if (!progressBar.current) return;

    if (userCode === 0 || noChangeErrorCodes.includes(userCode)) {
      progressBar.current.style.width = "0%";
      return;
    }

    if (requestType !== RequestTypes.fileProcessing) return;

    if (
      Object.keys(errorCodes).includes(String(userCode)) &&
      String(userCode)[0] === "5"
    ) {
      setProgressBarText('Did not process');
      setProgressBarSubText(
        <p className="sub-text"><a href="">Click here</a> to learn why</p>
      );
      progressBar.current.style.width = "40%";
      progressBar.current.style.backgroundColor = "red";
      return;
    }

    if (userCode !== 200) {
      setProgressBarText('Formatting only provided');
      setProgressBarSubText(
        <p className="sub-text"><a href="">Click here</a> to learn more and why</p>
      );
      progressBar.current.style.width = "60%";
      progressBar.current.style.backgroundColor = "blue";
      return;
    }

    setProgressBarText('Success!');
    setProgressBarSubText(<></>);
    progressBar.current.style.width = "100%";
    progressBar.current.style.backgroundColor = "green";
  }, [userCode]);

  return (
    <header>
      <div className="header-main_container">
        <div className="header-banners">
          <img width={dimensions.width >= 641 ? 411 : 300} src={ScoutBanner} alt="SCOUT - Schedule Order Upload Tool" />
        </div>

        <p className="text-alt">Version 1.2.0</p>

        <div className="schedule-upload">

          {/* <div className="progress_bar-container">
            <div className="progress_bar">
              <div ref={progressBar} className="progress">
                <div className="response-text">
                  <p>{progressBarText}</p>
                  {progressBarSubText}
                </div>
              </div>
            </div>

            <p>Upload Result Meter</p>
          </div> */}
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
